* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Cabin, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

h1 {
  font-size: 64;
  font-weight: bold;
}

h2 {
  font-size: 68px;
}

h3 {
  font-size: 42px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 16px;
}


p {
  font-weight: 300;
}

button {
  box-shadow: 0px 7px 23px -1px rgba(0, 0, 0, 0.25)
}

a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}



/* MENU NAVBAR */
.menubar {
  background-color: #27272B;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(115, 115, 115, 1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(115, 115, 115, 1);
  box-shadow: 0px 4px 4px 0px rgb(150, 150, 150);
  overflow: hidden;
  justify-content: stretch;
}

.button-default {
  background-color: #FAC141;
  color: #262A33;
  border: none;
  border-radius: 10px;
  width: 180px;
  font-weight: bold;
  cursor: pointer;
}



/** PROGRESS BAR **/
.progress-bar {
  background-color: #2d6ff4;
  height: 4px;
  position: fixed;
  top: 80px;
  z-index: 1;
}



/** HERO SECTION **/
.hero {
  background-color: #ffffff;
  padding-top: 80px;
  height: 90vh;
}

.outline {
  border: 2px solid #262A33;
  font-weight: 600;
  width: 180px;
  border-radius: 10px;
}



/** ANALYTICS SECTION **/
.analytics {
  height: 90vh;
  background-color: #27272B;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.analytics .col {
  padding: 0px 80px;
}



/** REVIEWS SECTION **/
.reviews {
  height: 60vh;
  align-content: center;
}

.reviewers-avatar {
  width: 80px;
}



/** PARTNERS SECTION **/
.partners {
  background-color: #EDF3FF;
  padding: 0px;
  margin-top: 30px;
}



/** FUNCTIONALITY SECTION **/
.dot {
  height: 25px;
  width: 25px;
  background-color: #2d6ff4;
  border-radius: 50%;
  border: 3px solid black;
  display: inline-block;
}

.vl {
  border-left: 5px dashed #262A33;
  height: 90%;
  margin-left: 58px;
}



/** PRICING SECTION **/
.pricing {
  background-color: #27272B;
  color: #ffffff;
  height: 80vh;
}

.pricing-header {
  color: #FAC141;
  text-align: center;
  font-weight: bold;
}

.pricing-card {
  border: 4px solid #FAC141;
  border-radius: 30px;
  padding: 14px;
  background-color: transparent;
  color: #ffffff;
}



/** FAQ SECTION **/
.accordion-item {
  border: none;
}

.accordion-item button {
  box-shadow: none;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  content: "";
  background-image: none;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff;
}

.accordion-button:focus {
  box-shadow: none;
}

.faq-icon {
  color: #407EF9;
  font-size: 36px;
  transition: transform 0.3s ease;
}



/** CTA SECTION **/



/** FOOTER **/
footer {
  background-color: #27272B;
  color: #ffffff;
  bottom: 0;
  font-weight: 300;
}

footer a {
  font-weight: 300;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  padding: 60px 40px 20px;
}

.footer-row {
  height: 40px;
}



/* UPSS - 404 */
.not-ready {
  background-color: #ffffff;
  padding-top: 60px;
}

.blue-text {
  color: #407EF9;
  font-weight: 600;
  font-size: 20px;
}



/* MOBILE VIEW */
@media screen and (max-width: 900px) {

  /** MOBILE FONTS **/

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 60px;
  }

  h3 {
    font-size: 37px;
  }

  h4 {
    font-size: 23px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 9px;
  }

  p {
    font-size: 14px;
  }



  /** MOBILE MENUBAR SECTION **/
  .navbar-brand img {
    width: 180px;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>');
  }

  .progress-bar {
    top: unset;
  }


  /** MOBILE HERO SECTION **/
  .hero {
    padding: 40px 20px;
    height: 100%;
  }

  .hero .btn {
    width: 160px;
    height: auto;
  }



  /** MOBILE ANALYTICS SECTION **/
  .analytics {
    height: auto;
  }

  .analytics .col {
    padding: 40px;
  }



  /** MOBILE REVIEWS SECTION **/



  /** MOBILE PARTNERS SECTION **/
  .partners {
    margin-top: 120px;
  }


  /** MOBILE FUNCTIONALITY SECTION **/



  /** MOBILE PRICING SECTION **/
  .pricing {
    height: unset;
  }


  /** MOBILE FAQ SECTION **/
  .accordion-button {
    display: block;
  }


  /** MOBILE CTA SECTION **/
  .cta .row {
    flex-direction: column-reverse;
  }



  /** MOBILE FOOTER SECTION **/
  footer h5 {
    font-weight: bold;
  }

  .footer-container {
    padding: 10px;
  }

  .footer-row {
    margin: 10px 0px;
  }
}